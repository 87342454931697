import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Privacy from './Privacy'
import Terms from './Terms'
import SetupGuide from './SetupGuide';
import ViewerShop from './ViewerShop';
import InstallApp from './InstallApp';
import ChristmasLoop23 from './landingPages/ChristmasLoop23';
import Golf from './landingPages/Golf';
import {GolfVideo} from './landingPages/GolfVideo';
import PracticeLoop from './landingPages/PracticeLoop';
import $ from 'jquery';
import LogoText from './components/logoText';
import CricketNew from './landingPages/Cricket-New';
import InstallPromo from './landingPages/InstallPromo';

class App extends React.Component {
  componentDidMount() {
    $('.atf-nav').append('<span class="atf-menu-toggle"><span></span></span>');
		$('.menu-item-has-children').append('<span class="atf-menu-dropdown-toggle"></span>');
    $('.atf-menu-toggle').on('click', function() {
        $(this).toggleClass("atf-toggle-active").siblings('.atf-nav-list').slideToggle();
    });
    $('.atf-menu-dropdown-toggle').on('click', function() {
        $(this).toggleClass('active').siblings('ul').slideToggle();
    });

    $('.atf-onepage-nav a').on('click', function() {
      $('.atf-menu-toggle').toggleClass("atf-toggle-active").siblings('.atf-nav-list').slideToggle();
    });
  };

  render() {
    return (
      <div className="page-wrapper">
        <header className="atf-site-header atf-style1 atf-sticky-header">
          <div className="atf-main-header">
            <div className="container">
              <div className="atf-main-header-in">
                <div className="atf-main-header-left">
                  <a className="atf-site-branding atf-white-logo" href="/#home"><LogoText light={true} style={{fontSize: '22px'}} /></a>
                </div>

                <div className="atf-main-header-right">
                  <div className="atf-nav">
                    <ul className="atf-nav-list atf-onepage-nav">
                      <li><a href="/#how" className="atf-smooth-move">How it works</a></li>
                      <li><a href="/#who-for" className="atf-smooth-move">For who?</a></li>
                      <li><a href="/#examples" className="atf-smooth-move">Watch</a></li>
                      <li><a href="/#features" className="atf-smooth-move">Features</a></li>
                      <li><a href="/#get-started" className="atf-smooth-move page-scroll atf-themes-btn ps-3 pe-3">Get started</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Router>
          <Switch>
            <Route path="/setup.html">
              <SetupGuide></SetupGuide>
            </Route>
            <Route path="/privacy.html">
              <Privacy />
            </Route>
            <Route path="/terms.html">
              <Terms></Terms>
            </Route>
            <Route path="/viewer-shop">
              <ViewerShop></ViewerShop>
            </Route>
            <Route path="/install">
              <InstallApp></InstallApp>
            </Route>
            <Route path="/promo">
              <InstallPromo></InstallPromo>
            </Route>
            <Route path="/cricket">
              <CricketNew></CricketNew>
            </Route>
            <Route path="/christmasloop23">
              <ChristmasLoop23></ChristmasLoop23>
            </Route>
            <Route path="/golf">
              <Golf></Golf>
            </Route>
            <Route path="/golf-video">
              <GolfVideo></GolfVideo>
            </Route>
            <Route path="/email-verified">
              <SetupGuide></SetupGuide>
            </Route>
            <Route path="/new">
              <PracticeLoop></PracticeLoop>
            </Route>
            <Route path="/">
              <PracticeLoop></PracticeLoop>
            </Route>
          </Switch>
        </Router>
        <Footer></Footer>
      </div>
    )
  }
}

function Footer() {
  return (
    <footer className="atf-footer-area">
      <div className="atf-section-padding pb-0">
        <div className="atf-footer-boottom text-center mt-4">
          <div className="row">
            <div className="col-md-12">
              <p><a style={{ fontSize: '12px' }} href="/">Home</a> | <a style={{ fontSize: '12px' }} href="setup.html">Setup guide</a> | <a style={{ fontSize: '12px' }} href="privacy.html">Privacy policy</a> | <a style={{ fontSize: '12px' }} href="terms.html">Terms of service</a> </p>
              <p style={{ fontSize: '12px' }}>&copy;Copyright - Ormond Code</p>
            </div>
          </div>
        </div>

      </div>
    </footer>
  )
}

export default App;
